.pageLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    transition: all .5s ease-in-out;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.content {
    transition: all .5s ease-in-out;
    width: 100%;
    height: 100%;
    min-height: 100%;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}
