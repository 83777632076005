
/* width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 0;
  border-radius: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99a0ac;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.clickable {
  cursor: pointer;
}
